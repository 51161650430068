import React from 'react'
import Footer from './footer'
import Nav from './nav'

const Layout = ({ title = 'MGPP', children }) => (
  <>
    <title>{title}</title>
    <div className="flex flex-col min-h-screen">
      {children}
      <Footer />
    </div>
  </>
)

export default Layout
